@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    font-family: "Open Sans";
    border-radius: 0px;
    -webkit-text-autocorrect: none;
    -webkit-autocapitalize: none;
}

.monaco-editor .view-lines,
.monaco-editor .inputarea {
    -webkit-text-autocorrect: none;
    -webkit-autocapitalize: none;
}

:root {
    --dark-background: rgb(10,10,10);
    --gray-mail: rgb(230,230,230);
    --accent-one: rgba(11, 115, 254, 255);
    --accent-two: rgba(41, 145, 274, 1);
    --shadow-one: 0px 10px 20px rgb(0,0,0,.1);
    --gray-half: rgba(251, 251, 251, 255);
    --gray-one: rgba(249, 249, 249, 255);
    --gray-one-half: rgba(244, 244, 244, 255);
    --gray-two: rgba(236, 236, 236, 255);
    --gray-three: rgba(232, 232, 232, 255);
    --gray-five: rgba(220, 220, 220, 255);
    --gray-four: rgba(192, 192, 192, 255);
    --gray-six: rgba(140, 140, 140, 255);
    --almost-black: rgb(60,60,60);
    --text-color-one: white;
    --border-radius-one: 3px;
    --border-bottom-color: rgba(210,210,210,255);
    --border-bottom-color-2: rgba(150,150,150,255);
    --nav-scale: 60px;
    --transition-nav: .7s cubic-bezier(0.4, 0, 0.2, 1);
    --text-color-course: black;
    --font-size-one: 14px;
    --font-size-two: 16px;
    --vs-background: rgba(31, 31, 31, 255);
    --vs-border: rgba(110, 110, 110, 255);
    --vs-icon: rgba(204, 204, 204, 1);
    --correct: rgba(1, 164, 33, 255);
    --incorrect: rgba(224, 6, 31, 255);
    --opacity-level: .3;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    width: 100%;
    height: var(--nav-scale);
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: var(--gray-one);
    font-size: var(--font-size-one);
    border-bottom: 1px solid var(--border-bottom-color);
    color: var(--blue-eight);
    box-sizing: border-box;
    z-index: 1000;
}

.icon {
    position: relative;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon.width {
    width: 100%;
}

.basic-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-icon {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--nav-scale);
}

.opacity {
    opacity: 0%;
}

.add-course  {
    height: 35px;
    padding-left: 20px;
    padding-right: 25px;
    background-color: var(--accent-one);
    color: var(--text-color-one);
    border-radius: var(--border-radius-one);
    font-size: var(--font-size-one);
    cursor: pointer;
    column-gap: 3px;
    font-weight: 600;
    text-align: center;
    text-wrap: nowrap;
    user-select: none;
    box-sizing: content-box;
    transition: width .7s cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
}

.add-course.open {
    width: 10px;
}

.add-course.closed {
    width: 100px;
}

.add-course:hover {
    background-color: var(--accent-two);
}

.icon-2-responsive, .icon-3, .icon-2 {
    position: relative;
    width: var(--nav-scale);
    height: var(--nav-scale);
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--border-bottom-color);
    transition: var(--transition-nav);
    box-sizing: border-box;
}

.icon-2-responsive {
    width: 240px;
}

.icon-2-responsive.open {
    width: 190px;
}

.icon-3-container {
    width: var(--nav-scale);
    height: var(--nav-scale);
    cursor: pointer;
}

.icon-3 {
    cursor: pointer;
    width: calc(var(--nav-scale) / 2);
    height: calc(var(--nav-scale) / 2);
    border-right: none;
    border-radius: calc(var(--nav-scale) / 2);
    border: 2px solid var(--border-bottom-color-2);
}

.icon-3-3, .icon-3-2 {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: calc(var(--nav-scale) / 2);
    border: 2px solid var(--border-bottom-color-2);
}

.icon-3-3 {
    border: none;
    border: 2px solid transparent;
}

.side-nav-container-open, .side-nav-container {
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    width: var(--nav-scale);
    border-right: 1px solid var(--border-bottom-color);
    z-index: 900;
    background-color: var(--gray-one);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: var(--nav-scale);
    transition: var(--transition-nav);
    overflow: auto;
    box-sizing: border-box;
}

.side-nav-container-open {
    width: 240px;
}

.side-nav-container-open.open {
    width: 190px;
}

.nav-icon-container {
    width: 100%;
    height: 100%;
    padding-top: var(--nav-scale);
}

.block {
    position: absolute;
    top: var(--nav-scale);
    right: 0%;
    width: var(--nav-scale);
    height: var(--nav-scale);
}

.block-2 {
    position: absolute;
    top: 0%;
    left: 0%;
    width: var(--nav-scale);
    height: var(--nav-scale);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.courses-container {
    width: 100%;
    position: relative;
}

.header {
    width: 100%;
    height: 250px;
    background-color: var(--accent-one);
    position: absolute;
    top: 0%;
    left: 0%;
}

.single-course-container, .courses-container-2 {
    position: static;
    margin-top: 90px;
    margin-left: 90px;
    margin-right: 90px;
    padding-bottom: 130px;
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}

.single-course-container.viewer {
    margin-right: 0px;
}

.single-course-container.height {
    height: calc(100vh - 60px);
    padding-bottom: 0px;
    justify-content: space-between;
    row-gap: 0px;
    min-height: 200px;
    box-sizing: border-box;
}

.single-course-container.small {
    height: auto;
}

.single-course-container {
    justify-content: flex-start;
    row-gap: 0px;
    height: auto;
}

.your-course, .your-courses {
    font-size: 25px;
    font-weight: 500;
    color: var(--text-color-one);
    width: 100%;
    text-align: left;
}

.your-course {
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.course-metadata-2, .course-metadata {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.course-metadata {
    column-gap: 50px;
    max-height: 130px;
}

.course-metadata-2 {
    color: var(--text-color-one);
    font-size: var(--font-size-one);
    font-weight: 400;
}

.semester-period-2, .semester-period {
    color: var(--text-color-one);
    font-size: var(--font-size-two);
    font-weight: 500;
    margin-bottom: -10px;
    width: 100%;
    text-align: left;
}

.semester-period-2 {
    margin-top: 20px;
    color: var(--almost-black);
}

.add-course-card, .course-card-container {
    width: 28%;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: .1s ease;
    font-size: var(--font-size-two);
    border: none;
}

.course-card-container:hover {
    text-decoration: underline;
}

.add-course-card {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--almost-black);
    font-weight: 600;
    background-color: var(--gray-two);
}

.add-course-card:hover {
    background-color: var(--gray-three);
}

.courses-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    width: 100%;
}

.course-card-bottom {
    width: 100%;
    color: var(--almost-black);
    font-size: var(--font-size-one);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    box-sizing: border-box;
    padding-top: 15px;
}

.course-card-bottom-background {
    background-color: var(--gray-two);
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.course-card-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    background-color: var(--gray-two);
    height: 75px;
}

.course-card-description, .course-card-name {
    width: 100%;
    color: var(--almost-black);
    text-align: left;
    font-weight: 700;
    font-size: var(--font-size-two);
    text-wrap: nowrap;
    overflow: auto;
}

.course-card-description {
    font-size: var(--font-size-one);
    font-weight: 500;
}

.course-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.arrow-container-4-2, .arrow-container-4, .arrow-container-3, .arrow-container-2-2, .arrow-container-2, .arrow-container-1-2, .arrow-container-1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--font-size-two);
    font-weight: 600;
    text-wrap: nowrap;
    column-gap: 3px;
}

.arrow-container-4-2 {
    width: 25%;
    justify-content: flex-end;
}

.arrow-container-4-2.fifty {
    width: 50%;
}

.arrow-container-4-2.fifty {
    width: 50%;
}

.arrow-container-4 {
    width: 30%;
    justify-content: flex-end;
}

.arrow-container-3 {
    width: 15%;
}

.status-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--font-size-two);
    font-weight: 600;
    text-wrap: nowrap;
    column-gap: 3px;
    cursor: pointer;
    user-select: none;
}

.arrow-container-2-2 {
    width: 35%;
}

.arrow-container-2 {
    width: 25%;
}

.arrow-container-1-2 {
    width: 50%;
}

.arrow-container-1 {
    width: 45%; 
}

.arrow-container-1.fifty {
    width: 50%;
}

.assessname {
    color: var(--almost-black);
}

.assessname:hover {
    text-decoration: underline;
    color: var(--accent-one);
}

.exams-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.exam-container {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--gray-four);
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.color {
    color: var(--text-color-course);
    font-size: var(--font-size-one);
    font-weight: 400;
}

.special {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.course-dropdown-container-custom {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 16px;
    position: relative;
    display: flex;
}

.course-dropdown-custom {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    position: absolute;
}

.course-dropdown-custom.space {
    width: 16px;
    height: 16px;
    border-radius: 0px;
    position: static;
    background-color: black;
}

.materials-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.no-under {
    text-decoration: none;
}

.bar {
    width: 1px;
    height: 100%;
    background-color: var(--text-color-one);
}

.semester-nav-info {
    width: 100%;
    padding: 20px;
    padding-top: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 15px;
    padding-bottom: 30px;
}

.text-side-nav {
    font-size: var(--font-size-one);
}

.text-side-nav.container {
    display: flex;
    width: 100%;
    height: 42px;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
}

.side-nav-info-header-special, .side-nav-info-header {
    font-weight: 700;
    font-size: var(--font-size-two);
    height: auto;
    position: relative;
}

.side-nav-info-header-special.open {
    font-size: var(--font-size-one);
}

.side-nav-info-header.title {
    font-size: 25px;
}

.meta-info-side {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow: auto;
    max-height: 320px;
    min-height: 35px;
}

.meta-info-side-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    row-gap: 12px;
    box-sizing: border-box;
}

.instructor-name {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    text-wrap: nowrap;
    font-size: var(--font-size-one);
}

.color-2 {
    background-color: black;
}

.wrap {
    text-wrap: wrap;
}

.font {
    font-size: var(--font-size-two);
    font-weight: 500;
}

.assessment-name-container {
    position: relative;
    cursor: pointer;
    text-wrap: wrap;
    word-break: break-word;
    padding-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    color: var(--text-color-course);
    text-decoration: underline;
}

.course-name {
    font-size: var(--font-size-one);
    text-wrap: wrap;
    word-break: break-word;
    font-size: max(16px, calc(1vw + 1vh + 1vmin));
}

.person-workspace-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-header-container {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: var(--text-color-one)
}

.person-edit-container {
    display: flex;
    text-wrap: nowrap;
    overflow: auto;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    column-gap: 20px;
    font-size: var(--font-size-two);
    font-weight: 600;
}

.account-settings-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 20px;
}

.required {
    color: red;
    font-weight: 600;
}

.required-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 3px;
    user-select: none;
    font-size: var(--font-size-one);
}

.input-field {
    max-width: 700px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.input-field.padding {
    margin-bottom: 70px;
}

.input-field.auto {
    height: auto;
}

.top-input {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    column-gap: 3px;
    font-size: var(--font-size-one);
    font-weight: 600;
    max-width: 700px;
    user-select: none;
}

.top-input.bottom {
    border-bottom: 2px solid var(--gray-four);
}

.bottom-input {
    width: 100%;
    max-width: 700px;
    border: none;
    border-bottom: 2px solid var(--gray-four);
    box-sizing: border-box;
    padding: 0px;
    font-size: var(--font-size-one);
}

.bottom-input.email {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: var(--font-size-one);
}

.input-container-pad {
    width: 91%;
    overflow: auto;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    display: flex;
}

.bottom-input:focus {
    outline: none;
    border-bottom: 2px solid var(--accent-one);
}

.primary-button-container {
    width: 140px;
    height: 25px;
    background-color: var(--accent-one);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    padding-left: 5px;
    padding-right: 5px;
    color: var(--text-color-one);
    font-size: var(--font-size-one);
    text-wrap: nowrap;
}

.save-changes-container {
    width: 100%;
    max-width: 700px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    flex-wrap: nowrap;
}

.save-changes-2, .save-changes, .cancel {
    width: 140px;
    height: 100%;
    background-color: var(--text-color-one);
    border: 2px solid var(--accent-one);
    color: var(--accent-one);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    transition: .2s cubic-bezier(0.4, 0, 0.2, 1);
    user-select: none;
    font-size: var(--font-size-one);
    border-radius: 50px;
    box-sizing: border-box;
}

.save-changes-2 {
    background-color: var(--accent-one);
    border: 2px solid var(--accent-one);
    color: var(--text-color-one);
    width: 140px;
    height: 50px;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: content-box;
    font-size: var(--font-size-one);
}

.cancel:hover {
    background-color: var(--accent-one);
    border: 2px solid var(--accent-one);
    color: var(--text-color-one);
}

.save-changes {
    background-color: var(--accent-one);
    border: 2px solid var(--accent-one);
    color: var(--text-color-one);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
}

.save-changes-2:hover, .save-changes:hover {
    background-color: var(--text-color-one);
    border: 2px solid var(--accent-one);
    color: var(--accent-one);
}

.eye {
    position: absolute;
    right: 0px;
    bottom: 0px;
    cursor: pointer;
}

.add-email-container {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    row-gap: 0px;
    font-size: var(--font-size-one);
}

.add-email-button {
    display: flex;
    column-gap: 0px;
    color: var(--accent-one);
    font-weight: 600;
    cursor: pointer;
    text-wrap: nowrap;
}

.add-email-button:hover {
    text-decoration: underline;
}

.gear {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
}

.icon-new {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    margin-right: 5px;
}

.paint-add-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 10px;
}

.paint-brush {
    cursor: pointer;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.side-nav-color-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.black-back {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,255);
    opacity: 50%;
    z-index: 10000;
}

.add-a-course-form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    height: fit-content;
    width: 500px;
    transform: translate(-50%, -50%);
    background-color: var(--text-color-one);
    max-width: 95vw; /* Maximum width of 95% of the viewport width */
    max-height: 95vh; /* Maximum height of 95% of the viewport height */
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto; /* Allows scrolling if content overflows */
    box-sizing: border-box; /* Ensures padding and borders are included in the width and height calculations */
    padding: 20px;
    flex-direction: column;
    height: auto;
}

.enter-entry-code-container {
    height: fit-content;
    width: 500px;
    background-color: var(--text-color-one);
    max-width: 95vw; /* Maximum width of 95% of the viewport width */
    max-height: 95vh; /* Maximum height of 95% of the viewport height */
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto; /* Allows scrolling if content overflows */
    box-sizing: border-box; /* Ensures padding and borders are included in the width and height calculations */
    padding: 20px;
    flex-direction: column;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    overflow: hidden;
}

.add-a-course-form-content-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.add-course-title {
    color: var(--almost-black);
    font-size: 25px;
    font-weight: 700;
}

.form-width {
    width: 100%;
}

.banner {
    width: 100%;
    font-size: var(--font-size-one);
    background-color: rgba(245, 247, 249, 255);
    border-radius: 5px;
    color: var(--almost-black);
    border: 1px solid var(--gray-mail);
    font-weight: 600;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
}

.logout {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    font-size: var(--font-size-one);
    cursor: pointer;
    color: var(--accent-one);
}

.logout:hover {
    text-decoration: underline;
}

.no-assignments-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

.no-assignments {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--almost-black);
    row-gap: 20px;
    font-size: var(--font-size-one);
    font-weight: 500;
    text-align: center;
}

.assessment-page-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--text-color-one);
    font-size: 25px;
    font-weight: 500;
}

.assessment-page-bottom {
    color: var(--text-color-one);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    font-size: var(--font-size-two);
    font-weight: 600;
}

.assessment-page-content {
    width: 100%;
    height: 100%;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 5%;
    row-gap: 20px;
}

.assessment-page-content.small {
    flex-direction: column;
}

.left-info {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 150;
    position: relative;
    padding-bottom: 45px;
}

.right-info {
    width: 65%;
    height: 100%;
    color: var(--almost-black);
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    font-size: var(--font-size-two);
    background-color: var(--text-color-one);
    text-align: center;
    padding: 0px;
}

.right-info.small {
    width: 100%;
    height: 250px;
}

.left-info-content {
    background-color: var(--gray-two);
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    row-gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;
    row-gap: 17px;
}

.text-side-nav.display {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 3px;
}

.text-side-nav.display-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    font-size: var(--font-size-one);
    font-weight: 400;
}

.pdf-content-container {
    width: 100%;
    height: 100%;
    min-height: 375px;
    background-color: var(--text-color-one);
    z-index: 150;
}

.pdf-content-container.small {
    width: 100%;
    min-height: 375px;
    margin-bottom: 50px;
}

.pdf-content-container.custom-exam {
    position: fixed;
    top: 60px;
    left: 0px;
    height: calc(100vh - 60px);
    box-sizing: border-box;
}

.pdf-content-container.special-height {
    height: calc(100vh - 60px);
}

.wrapper-for-split-screen-custom-exam {
    height: calc(100vh - 60px);
    overflow: auto;
    width: 100%;
    min-height: 275px;
}

.wrapper-for-split-screen-custom-exam.new-height {
    height: calc((100vh - 60px) * .65);
}

.pdf-content-container.false {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    row-gap: 10px;
    border: 2px solid var(--gray-two);
    font-size: var(--font-size-one);
    text-align: center;
}

.test {
    border: 2px solid black;
}

.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: 50%;
    animation: blink 1.4s infinite both;
    background-color: #333;
}

.dot.white {
    background-color: #333;
}

.dot:nth-child(1) {
animation-delay: 0s;
}

.dot:nth-child(2) {
animation-delay: 0.2s;
}

.dot:nth-child(3) {
animation-delay: 0.4s;
}

@keyframes blink {
0%, 80%, 100% {
    opacity: 0;
}
40% {
    opacity: 1;
}
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-banner-container {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 60px;
    left: 0px;
    background-color: rgba(204,0,0, .5);
    padding-left: 70px;
    padding-right: 10px;
    color: var(--text-color-one);
    align-items: center;
    justify-content: space-between;
}

.text-error {
    width: 500px;
    text-wrap: nowrap;
    overflow: auto;
    height: 60px;
}

.feedback-window-container {
    width: 100%;
    height: 100%;
    min-height: 175px;
    border-radius: 15px;
    background-color: var(--gray-one);
    padding: 10px;
    border: 1px solid var(--gray-four);
    outline: none;
    font-size: var(--font-size-one);
}

.custom-color {
    width: 40px;
    height: 30px;
    outline: none;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    color: none;
    padding: 0px;
}

.overflow-name {
    text-wrap: nowrap;
    overflow: auto;
    max-width: max(calc(100% - 500px));
}

.overflow-name-small {
    text-wrap: nowrap;
    overflow: auto;
    max-width: max(calc(100% - 200px));
}

.overflow-name-small-small {
    text-wrap: nowrap;
    overflow: auto;
    max-width: 100%;
}

.overflow-code {
    text-wrap: nowrap;
    font-size: var(--font-size-one);
}

.search-holder {
    position: absolute;
    top: 0px;
    right: 0px;
}

.search-holder-bottom {
    position: absolute;
    top: 70px;
    left: 0px;
}

.search-container {
    width: 100%;
    position: relative;
}

.search-input {
    width: 100%;
    background-color: transparent;
    border: none;
    height: 25px;
    font-size: var(--font-size-one);
    color: var(--text-color-one);
    outline: none;
    border-bottom: 1px solid var(--text-color-one);
    padding-left: 18px;
}

.search-input::placeholder {
    color: var(--text-color-one);
}

.search-icon {
    position: absolute;
    left: 0px;
    bottom: 9px;
}

.info-container {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1.5px solid var(--almost-black);
    box-sizing: border-box;
}

.full {
    margin-top: 60px;
    margin-left: 60px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    width: 100%;
}

.assessment-page-content.min {
    column-gap: 0px;
    row-gap: 0px;
}

.left-info.fullscreen {
    padding-bottom: 0px;
    width: 30%;
    min-width: 300px;
}

.pdf-container-non-multi {
    width: 100%;
    height: 100%;
}

.left-info.small {
    width: 100%;
    height: 350px;
}

.loading-zip-container {
    width: 100%;
    height: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-one);
    color: var(--almost-black);
}

.loading-zip-container-2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-one);
    color: var(--almost-black);
}

.location-settings-hover {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    width: fit-content;
    height: fit-content;
    z-index: 2000;
}

.location-settings-hover-item {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    background-color: var(--accent-one);
    color: var(--text-color-one);
    box-shadow: 0px 2px 20px rgba(0,0,0, .5);
    border-radius: 5px;
    cursor: pointer;
}

.editor-tree-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.editor-container {
    width: calc(100% - 240px);
    height: 100%;
    background-color: inherit;
    padding-top: 30px;
    position: relative;
}

.file-name-header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: fit-content;
    max-width: 100%;
    overflow: auto;
    text-wrap: nowrap;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: inherit;
    font-size: var(--font-size-one);
}

.file-visualizer-open {
    width: 240px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    background-color: inherit;
    overflow: auto;
    padding: 10px;
    min-height: 300px;
}

.node {
    color: inherit;
    font-size: var(--font-size-one);
    cursor: pointer;
}

.node-hover:hover {
    text-decoration: underline;
}

.node-current {
    border-bottom: 1px solid var(--vs-icon);
}

.zip-download-wrapper {
    position: absolute;
    top: 0px;
    right: 50px;
    width: fit-content;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
}

.zip-download-container {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.zip-download-container:hover {
    background-color: rgba(110, 110, 110, .3);
}

.top-right-item {
    background-color: var(--accent-one);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
    column-gap: 10px;
    width: 110px;
    height: 35px;
    font-size: var(--font-size-one);
    color: var(--text-color-one);
    cursor: pointer;
    user-select: none;
}

.top-right-item:hover {
    background-color: var(--accent-two);
}

.test-taking-info {
    width: 100%;
    height: 100vh;
    min-height: 500px;
    padding-left: 65px;
    padding-top: 65px;
    padding-right: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.test-taking-info-container {
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0,0,0,.5);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.total {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.total-2 {
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-view {
    row-gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text-wrapper {
    text-align: center;
    padding: 5px;
    word-wrap: break-word;  /* Break long words if needed */
    overflow-wrap: break-word;
    font-size: var(--font-size-two);
    color: var(--almost-black);
    width: 100%;
}

.top-right-custom-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    color: var(--almost-black);
}

.top-right-item {
    background-color: var(--accent-one);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    width: 110px;
    height: 35px;
    font-size: var(--font-size-one);
    color: var(--text-color-one);
    cursor: pointer;
    user-select: none;
}

.top-right-item:hover {
    background-color: var(--accent-two);
}

.true-false-question-view {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--gray-five);
    padding: 10px;
    background-color: var(--gray-one);
    word-break: normal;
    overflow-wrap: normal;
    white-space: normal;
    hyphens: none;
}

.true-false-top {
    padding: 5px;
    height: fit-content;
    width: 100%;
    text-align: left;
    font-size: var(--font-size-one);
}

.question-number {
    width: 100%;
    height: fit-content;
    padding: 5px;
    height: 30px;
    font-size: var(--font-size-one);
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.true-false-answer-block {
    padding: 5px;
    width: 100%;
    height: fit-content;
    display: flex;
    column-gap: 10px;
    font-size: var(--font-size-one);
    justify-content: flex-start;
    align-items: center;
}

.bubble-with-text-view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.bubble {
    border-radius: 19px;
    background-color: var(--text-color-one);
    border: 1px solid var(--gray-five);
    box-sizing: border-box;
    min-height: 19px;
    min-width: 19px;
    cursor: pointer;
}

.bubble.bubbled {
    background-color: var(--accent-one);
    border: none;
}

.bubble.incorrect {
    background-color: var(--incorrect);
    border: none;
}

.bubble.correct {
    background-color: var(--correct);
    border: none;
}

.bubble.correct-but-not-selected {
    background-color: var(--correct);
    opacity: var(--opacity-level);
    border: none;
}

.select-all-that-apply-array {
    padding: 5px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: var(--font-size-one);
    justify-content: center;
    align-items: flex-start;
}

.square {
    border-radius: 3px;
    background-color: var(--text-color-one);
    border: 1px solid var(--gray-five);
    box-sizing: border-box;
    min-height: 18px;
    min-width: 18px;
    cursor: pointer;
}

.square.bubbled {
    background-color: var(--accent-one);
    border: none;
}

.square.incorrect {
    background-color: var(--incorrect);
    border: none;
}

.square.correct {
    background-color: var(--correct);
    border: none;
}

.square.correct-but-not-selected {
    background-color: var(--correct);
    opacity: var(--opacity-level);
    border: none;
}

.square-with-text-view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.edit-select {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.text-response-area {
    padding: 5px;
    width: 100%;
    height: fit-content;
}

.text-response-area-input {
    padding: 5px;
    background-color: var(--text-color-one);
    border-radius: 5px;
    width: 100%;
    height: fit-content;
    min-height: 175px;
    border: 1px solid var(--gray-five);
    outline: none;
    font-size: var(--font-size-one);
    color: var(--almost-black);
}

.code-response-area {
    border-radius: 5px;
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--gray-five);
    overflow: hidden;
}

.code-response-area-wrapper {
    width: 100%;
    padding: 5px;
    height: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.code-editor-header {
    width: 100%;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2px;
}

.pts {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.select-edit, .grading-box-question {
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 2px solid var(--gray-five);
    width: 40px;
    height: 18px;
    text-align: center;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    padding-bottom: -2px;
}

.select-edit:focus , .grading-box-question:focus {
    border-bottom: 2px solid var(--accent-one);
}

.select-edit {
    text-align: left;
    width: 100%;
}

.no-questions {
    width: 100%;
    height: min(100vh);
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-exam-wrapper-view {
    width: 100%;
    max-width: 800px;
    height: fit-content;
    padding: 20px;
    border-radius: 5px;
    background-color: var(--gray-two);
    border: 1px solid var(--gray-five);
    color: var(--almost-black);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 20px;
    word-wrap: break-word;  /* Ensures text wraps */
    word-break: break-all;  /* Breaks the word at any character */
    white-space: normal;    /* Allows text to wrap onto the next line */
}

.custom-programming-exam-wrapper {
    width: 100%;
    height: 100%;
    padding: 15px;
    padding-top: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    position: relative;
}

.wrapper-for-current-viewer, .split-screen-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.wrapper-for-current-viewer {
    padding-top: 60px;
}

.grading-pdf-question {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
}

.points {
    font-size: var(--font-size-two);
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.question-tests-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 15px;
    flex-direction: column;
    overflow: auto;
}

.a-single-test {
    width: 100%;
    height: auto;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-one);
    color: var(--almost-black);
    font-weight: 600;
    box-sizing: border-box;
    border: 1px solid var(--gray-four);
    background-color: var(--gray-one-half);
    border-radius: 5px;
    padding: 10px;
    word-break: break-all;
    column-gap: 15px;
}

.left-of-test {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.right-of-test {
    text-wrap: nowrap;
}

.if-output-container {
    flex-direction: column;
    display: flex;
    width: 100%;
}

.bottom-output {
    padding: 5px;
    text-align: left;
    font-size: var(--font-size-one);
    background-color: var(--gray-half);
    border: 1px solid var(--gray-four);
    border-top: none;
    word-break: break-all;
}

.timer {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--font-size-two);
}

.warning {
    position: fixed;
    width: 350px;
    height: 160px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--gray-mail);
    padding: 10px;
    padding-top: 50px;
    color: var(--almost-black);
    background-color: var(--text-color-one);
    box-sizing: border-box;
}

.warning-title {
    background-color: var(--accent-one);
    font-size: var(--font-size-two);
    font-weight: 600;
    border-radius: 10px 10px 0px 0px;
    width: calc(100% + 2px);
    top: -1px;
    left: -1px;
    position: absolute;
    color: var(--text-color-one);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--accent-one);
    padding: 10px;
}

.warning-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.x-warning {
    position: absolute;
    top: 5px;
    right: 3px;
    cursor: pointer;
    color: var(--text-color-one);
}

.dismiss {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    height: 30px;
    transform: translate(-50%, 0%);
}

.loading-for-timer {
    font-size: var(--font-size-two);
    color: var(--almost-black);
}

.signup-container {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background-color: var(--accent-one);
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-from-landing {
    position: absolute;
    right: 0px;
    bottom: -10px;
    transform: translate(0px, -50%);
}

.banner-2 {
    width: 100%;
    font-size: var(--font-size-one);
    background-color: var(--accent-one);
    color: var(--distinct);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    min-height: 39px;
    border: none;
    color: var(--text-color-one)
}

.support-container {
    margin-top: 20px;
    width: 100%;
    max-width: 700px;
    height: auto;
    row-gap: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.send {
    color: white;
    background-color: var(--accent-one);
    border-radius: 5px;
    cursor: pointer;
    width: 160px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-one);
}

.support-item {
    width: 100%;
    max-width: 700px;
    padding: 10px;
    outline: none;
    border: 1px solid var(--gray-mail);
    border-radius: 5px;
    font-size: var(--font-size-one);
    background-color: var(--gray-one);
}

.pp-and-tac {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    font-size: var(--font-size-one);
}

.item-of-tac {
    font-size: var(--font-size-one);
    cursor: pointer;
    color: var(--accent-one);
}

.item-of-tac:hover {
    text-decoration: underline;
}

.signup-container {
    width: 100vw;
    height: 100vh;
    background-color: var(--accent-one);
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-button {
    color: rgb(100,100,100);
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-button.hovered:hover {
    color: var(--accent-one)
}

.back-absolute {
    position: absolute;
    left: 12.5px;
    top: 73.5px;
    display: flex;
    column-gap: 0px;
    justify-content: flex-start;
    align-items: center;
    color: var(--almost-black);
    font-size: 12px;
    cursor: pointer;
    font-weight: 700;
}

.back-button.hovered:hover {
    color: var(--accent-one)
}

.back-absolute:hover {
    color: var(--accent-one);
    text-decoration: underline;
}

.outside-click {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
}

.nav-icon-with-back {
    position: absolute;
    top: var(--nav-scale);
    right: 0%;
    width: var(--nav-scale);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.collapsed.hover:hover {
    border-radius: 10px;
    background-color: var(--gray-three);
}

.answer-string {
    font-size: var(--font-size-one);
    color: var(--almost-black)
}

.outside-click-side-nav {
    position: fixed;
    width: 60px;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
}

.wrapper-for-entry {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
}

.explanation-wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-radius: 10px;
}

.explanation-wrapper-text {
    padding: 5px;
    font-size: var(--font-size-one);
    color: var(--almost-black);
}

.extra {
    word-break: break-word;
}

.attempt {
    font-size: var(--font-size-two);
    padding-left: 2px;
}

.lambda-results-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
    row-gap: 15px;
}

.fullscreen-editor {
    position: fixed;
    width: 100vw;
    height: calc(100vh - var(--nav-scale));
    top: var(--nav-scale);
    left: 0px;
    z-index: 1000;
}

.fullscreen-editor-side-bar {
    position: fixed;
    width: calc(100vw - var(--nav-scale));
    height: calc(100vh - var(--nav-scale));
    top: var(--nav-scale);
    left: var(--nav-scale);
    z-index: 1000;
}

.run-tests {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.greyed-out-run-tests, .greyed-out-run-tests:hover {
    cursor: auto;
    background-color: var(--gray-four);
    border: 1px solid var(--gray-five);
    font-size: var(--font-size-one);
    color: var(--text-color-one);
    user-select: none;
}

.run-new {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
    flex-direction: column;
}

.tests-center {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tippy-box[data-theme~='custom-tooltip'] {
    background-color: #333; /* Background color */
    color: #fff; /* Text color */
    font-size: 12px; /* Font size */
    border-radius: 4px; /* Rounded corners */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); /* Shadow */
}
  
.tippy-arrow {
    color: #333; /* Match the tooltip background color */
}

.docs-wrapper-icon {
    border-radius: 20px;
    border: 1px solid var(--text-color-one);
    cursor: pointer;
    max-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-sub-take-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding-left: 10px;
}

.auto-save {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    color: var(--gray-six);
    font-size: 12px;
}

.questions-top-level {
    position: fixed;
    top: 75px;
    right: 15px;
    border-radius: 5px;
    background-color: var(--gray-half);
    border: 1px solid var(--gray-five);
    padding: 10px;
    padding-bottom: 15px;
    padding-top: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--gray-six);
    font-size: var(--font-size-two);
    font-weight: 600;
    row-gap: 4px;
    max-height: calc(100vh - 75px - 15px)
}

.questions-right-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.each-q-container {
    margin-left: 20px;
    font-size: var(--font-size-one);
    color: var(--almost-black);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
}

.embedded-qs-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    overflow: auto;
    width: 100%;
}

.question-navigate:hover {
    text-decoration: underline;
    cursor: pointer;
}

.burger-in-exam {
    cursor: pointer;
}


.grid-number-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    color: inherit;
}

.flag-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}

.svg-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--accent-one);
    padding: 3px;
    color: var(--accent-one);
    border-radius: 25px;
    background-color: var(--gray-two);
}

.wrapper-run-tests-right-top {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
}